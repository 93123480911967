<template>
	<div class="page">
		<Tables ref="xTable" :tableName="tableName" :tableData="List" :totalPage="totalPage" :isLoading="isLoading"
			:searchFormData="searchFormData" :showRefresh="true" :showSearch="true"
			:showAdd="$hasAccess('hlht.manager/edit')" addName="新增" @getList="getList" @clearSearch="clearSearch"
			@addItemInTable="addOne">

			<!--搜索自定义内容-->
			<el-form-item slot="search-item">
				<el-select v-model="searchFormData.OperatorID" size="small" clearable style="width: 100px" placeholder="企业名称">
					<el-option v-for="item in OperatorList" :label="item.operator_name" :value="item.OperatorID" :key="item.OperatorID"></el-option>
				</el-select>
			</el-form-item>
			<!--表格内容-->
			<vxe-table-column slot="table-item" field="OperatorID" title="企业代码" align="center" />
			<vxe-table-column slot="table-item" field="operator_name" title="企业名称" align="center" />
			<vxe-table-column slot="table-item" field="status" title="状态" align="center">
				<template v-slot="{ row }">
					<span :style="{color:row.statusValue == '启用'?'green':''}">{{ row.statusValue }}</span>
				</template>
			</vxe-table-column>	
			<vxe-table-column slot="table-item" field="ctime" title="创建时间" align="center" />
			<vxe-table-column slot="table-item" field="stoptime" title="停止时间" align="center" />
			<vxe-table-column slot="table-item" title="操作" align="center">
				<template v-slot="{ row }">
					<el-button size="small" plain @click="detail(row)">详情</el-button>
					<el-button size="small" plain @click="open(row)">{{ row.statusText }}</el-button>
				</template>
			</vxe-table-column>
		</Tables>

		<!-- 编辑 -->
		<el-dialog title="新增" :visible.sync="editDialogShow" :close-on-click-modal="false"
			:close-on-press-escape="false" width="500px">
			<el-form :model="editDialogFormData" :rules="rules" ref="editForm" label-width="100px" class="form">
				<el-form-item label="企业名称" prop="OperatorID">
					<el-select v-model="editDialogFormData.OperatorID" size="small" clearable style="width: 100px" placeholder="企业名称">
						<el-option v-for="(item,index) in OperatorList" :label="item.operator_name" :value="item.OperatorID" :key="index"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="企业编码">
					<el-input v-model="editDialogFormData.OperatorID" disabled style="width: 140px;"></el-input>
				</el-form-item>
				<el-form-item label="状态">
					<el-switch v-model="editDialogFormData.status" :active-value="1" :inactive-value="0"></el-switch>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button type="primary" size="small" plain icon="el-icon-check" @click="saveEdit()">保 存
				</el-button>
				<el-button size="small" plain icon="el-icon-close" @click="closeDialog">取 消
				</el-button>
			</div>
		</el-dialog>
		<!-- 启动停用 -->
		<el-dialog title="渠道停用" :visible.sync="openShow" :close-on-click-modal="false" :close-on-press-escape="false" width="400px">
			<el-radio v-model="editDialogFormData.status" :label="0">即时停用</el-radio><br />
			<el-radio v-model="editDialogFormData.status" :label="1">定时停用</el-radio>
			<el-date-picker v-model="editDialogFormData.stoptime" value-format="yyyy-MM-dd HH:mm:ss" size="small" type="datetime" placeholder="选择日期时间"></el-date-picker>
			<div slot="footer" class="dialog-footer">
				<el-button type="primary" size="small" plain icon="el-icon-check" @click="saveStop()">保 存
				</el-button>
				<el-button size="small" plain icon="el-icon-close" @click="closeDialog">取 消
				</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import Tables from '@/components/tables'
	export default {
		name: 'market-activitycommon-list',
		components: {
			Tables
		},
		data() {
			return {
				// 表格信息
				tableName: '站点推送',
				isLoading: false,
				totalPage: 0,
				searchFormData: {},
				List: [], //推送的组织列表
				OperatorList: [], //组织列表
				rules: {
					OperatorID: [{
						required: true,
						message: '请选择企业',
						trigger: 'change'
					}]
				},
				// 编辑
				editDialogShow: false,
				editDialogFormData: {},
				openShow: false,
			}
		},
		computed: {
		},
		mounted() {
			this.getList();
			//获取优惠券列表
			const params = {
				token: this.$store.state.user.token,
				page: 1,
				size: 100,
			};
			this.$api.Hlht.Manager.operatorlist(params).then((res)=>{
				this.OperatorList = res;
			});

		},
		activated() {},
		methods: {
			// 表格列表
			async getList() {
				let params = {
					token: this.$store.state.user.token,
					page: 1,
					size: 20
				}
				params = Object.assign(params, this.searchFormData);
				const res = await this.$api.Hlht.Manager.list(params, {});
				this.List = res.data;
			},
			// 重置搜索
			clearSearch() {
				this.searchFormData = {}
			},
			// 重置form
			resetFormData() {
				this.editDialogFormData = {status:1,stoptime:null}
				if (this.$refs.editForm) {
					this.$refs.editForm.resetFields()
				}
			},
			// 新增
			addOne() {
				this.resetFormData()
				this.editDialogShow = true
			},
			// 启用 停止
			open(row) {
				this.editDialogFormData = row;
				if(row.statusText == "启用"){
					this.editDialogFormData.stoptime = null;
					this.editDialogFormData.status = 1;
					this.saveStop();
				}else{
					this.openShow = true;
				}
			},
			async saveStop(){
				let params = Object.assign({token: this.$store.state.user.token}, this.editDialogFormData);
				await this.$api.Hlht.Manager.edit(params);
				this.$notify({
					title: '成功',
					message: '操作成功',
					type: 'success'
				})
				this.closeDialog()
				this.$refs.xTable.refreshTable()
			},
			// 关闭弹窗
			closeDialog() {
				this.editDialogShow = false;
				this.openShow = false;
			},
			// 返券记录
			detail(row) {
				this.$router.push({
					name: "hlht-detail",
					query: {id:row.OperatorID},
				});
			},
			saveEdit() { // 编辑保存
				this.$refs.editForm.validate(async valid => {
					if (valid) {
						let params = Object.assign({token: this.$store.state.user.token}, this.editDialogFormData);
						await this.$api.Hlht.Manager.edit(params);
						this.$notify({
							title: '成功',
							message: '操作成功',
							type: 'success'
						})
						this.closeDialog()
						this.$refs.xTable.refreshTable()
					}
				})
			},
		}
	}
</script>

<style lang="scss" scoped>
</style>
